.flags-select ul {
    margin-top: -206px;
}

.flags-select {
    border: none;
    display: flex;
    padding: 0px !important;
}

.flags-select ul li {
    margin-left: -3px;
}

.flags-select span::after {
    display: none;
}

.flags-select button {
    border: none;
    padding: 0px;
}

/* message owner */
.css-wgo2tv {
    background: #3485F7 !important;
    border-radius: 16px !important;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    max-width: 90%;
    padding: 16px !important;
    word-wrap: break-word;
}

/* message other */
.css-xn25a8 {
    background-color: white !important;
    border-radius: 16px !important;
    color: black !important;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    max-width: 90%;
    padding: 16px !important;
    word-wrap: break-word;
}

.css-akicnj {
    background-color: #F3F5F7 !important;
}
