.admin-page .translate-action-text {
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
}

.admin-page .timeline {
  align-content: center;
  color: rgba(0, 0, 0, 0.64);

  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: 18px;
}

.admin-page .single-translate-text-wrapper,
.single-original-wrapper.inbound {
  margin-bottom: 16px;
}

.admin-page .original-wrapper {
  color: rgba(0, 0, 0, 0.64);
  padding: 16px 0px;
}
