.customer-page .translate-action-text {
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
}

.customer-page .translate-text-wrapper {
  color: #1d1d1d;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.customer-page .single-translate-text-wrapper,
.single-original-wrapper.inbound {
  margin-bottom: 16px;
}

.customer-page .original-wrapper {
  color: rgba(0, 0, 0, 0.64);
  padding: 16px 0px;
}

.customer-page .timeline {
  align-content: center;
  color: rgba(0, 0, 0, 0.64);

  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: 18px;
}

.customer-page > div > div > div > div:first-child {
  display: none;
}
